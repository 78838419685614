<course-modal
    [modalWidth]="900"
>
    <ng-container slot="modal">
        <div class="websiteSectionTitle">
            <h1 i18n="@@website.bookADemoTitle">Book a Tyyp demo with Ken</h1>
            <p i18n="@@website.bookADemoDescription">In this online meeting we briefly explain how Tyyp works and what it can mean for you and your students.</p>
        </div>

        <div class="meetings-iframe-container" [attr.data-src]="demoFormUrl + '?embed=true'"></div>
    </ng-container>

    <ng-container slot="controls">

    </ng-container>
</course-modal>
