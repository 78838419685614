import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '../../../../shared/services/language.service';

@Component({
    selector: 'course-book-a-demo',
    templateUrl: './bookADemo.component.html',
    styleUrls: ['./bookADemo.component.scss'],
})
export class BookADemoComponent implements OnInit {
    @Input() type: 'school' | 'business' = 'school';
    demoFormUrl: string;

    constructor(
        private languageService: LanguageService
    ) {
    }

    ngOnInit() {
        const languageCode = this.languageService.getActiveLanguage().code;

        switch (languageCode) {
            case 'fr':
                this.demoFormUrl = 'https://meetings-eu1.hubspot.com/ken-de-pooter/tyyp-demo-avec-ken-fr';
                break;

            case 'nl':
            default:
                this.demoFormUrl = 'https://meetings-eu1.hubspot.com/ken-de-pooter/tyyp-demo-met-ken';
        }

        this.initCalendarScript();
    }

    initCalendarScript() {
        const script = document.createElement('script');
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}

