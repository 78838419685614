<div class="individual slideUp">
    <img
        ngSrc="assets/images/shop/individual_header.webp"
        i18n-alt="@@website.shop.individualAlt"
        alt="Person types on keyboard"
        width="350"
        height="150"
    />
    <div class="productDescription">
        <h3 i18n="@@website.shop.individual">Individual</h3>
        <p
            i18n="@@website.shop.individualInfo"
        >Complete the course at your own pace. Everything is explained in great detail, so you can learn touch typing in no time.</p>
        <ul class="checklist">
            <li>
                <span
                    i18n="@@website.shop.individual1"
                ><strong>Valid for a whole year</strong></span>
            </li>
            <li>
                <span
                    i18n="@@website.shop.individual2"
                >Up to <strong>80% cheaper</strong> than alternatives</span>
            </li>
            <li>
                <span
                    i18n="@@website.shop.individual3"
                >Get <strong>instant access</strong></span>
            </li>
        </ul>
    </div>
    <div class="purchaseInfo">
        <div *ngIf="products && products.individual.discountOnFullPrice > 0" class="discount">
            <p>
                <span class="discountPercentage">-{{ products.individual.discountOnFullPrice }}%</span>
                {{ products.individual.saleName ? products.individual.saleName[languageService.getActiveLanguage().code] : '' }}
            </p>
        </div>

        <div class="pricing">
            <span class="price">
                <span *ngIf="products && products.individual.discountOnFullPrice > 0" class="originalPrice">
                    <span i18n="@@msrp" class="msrp">MSRP</span> <span class="priceNr">{{ products.individual.fullPriceIncl | formatCurrency:currency }}</span>
                </span>
                <span class="priceNumber" [innerHTML]="products?.individual.calculatedIncl | formatCurrency:currency:true"></span>
                <span class="exclIncl">
                    <ng-container i18n="@@inclTax">Incl. tax</ng-container>
                </span>
            </span>
        </div>

        <p class="cta">
            <button
                (click)="addToCart()"
                mat-flat-button
                class="productBtn"
                i18n="@@website.shop.order"
            >Buy now</button>
        </p>
    </div>
</div>

<div class="school slideUp">
    <img
        ngSrc="assets/images/shop/school_header.webp"
        i18n-alt="@@website.shop.schoolAlt"
        alt="Student and teacher at school"
        width="350"
        height="150"
    />
    <div class="productDescription">
        <h3 i18n="@@website.shop.school">School</h3>
        <p
            i18n="@@website.shop.schoolInfo"
        >Enjoy extra favourable rates and use the follow-up platform for free! This makes it really easy to follow up your pupils.</p>
        <course-school-features *ngIf="products" [product]="products.school"></course-school-features>
    </div>
    <div class="purchaseInfo">
        <p class="cta infoCta">
            <a
                [routerLink]="'/' + navigationService.getRouteTranslation('forSchools')"
                mat-stroked-button
                class="productBtn"
                i18n="@@moreInfo"
            >More info</a>
        </p>
        <p class="cta">
            <a
                routerLink="/school-registration"
                mat-flat-button
                class="productBtn"
                i18n="@@website.startFree"
            >Start for free</a>
        </p>
    </div>
</div>
