<div class="individual" [class.endOfTrial]="endOfTrial" [class.trialCTA]="trialCTA">
    <div
        class="img"
        [class.slideRight]="stateService.getFirstHeroAnimationSkipped()"
    >
        <div class="circle"></div>
        <img
            ngSrc="assets/images/shop/{{languageCode}}/{{ product.image }}.webp"
            i18n-alt="@@website.shop.productAlt"
            alt="Typing course product"
            width="500"
            height="412"
        />
    </div>

    <div
        class="description"
        [class.slideLeft]="stateService.getFirstHeroAnimationSkipped()"
    >
        <h1>
            <ng-container *ngIf="!product.dlc" i18n="@@website.shop.individualTitle">Get started with Tyyp!</ng-container>
            <ng-container *ngIf="product.dlc" i18n="@@upgrade.title">Continue in the Advanced Typing Course!</ng-container>
        </h1>
        <p>
            <ng-container *ngIf="!product.dlc" i18n="@@website.shop.individualInfo">Complete the course at your own pace. Everything is explained in great detail, so you can learn touch typing in no time.</ng-container>
            <ng-container *ngIf="product.dlc" i18n="@@upgrade.sub">Want to learn to type even faster? Continue in the Advanced Typing Course with even more exercises, games and videos!</ng-container>
        </p>

        <ul class="checklist">
            <li *ngIf="endOfTrial && !product.dlc">
                <span
                    i18n="@@website.shop.individualKeepProgress"
                >Your <strong>progress</strong> of the trial version <strong>will be saved</strong></span>
            </li>
            <ng-container *ngIf="product.dlc">
                <li><span i18n="@@discountExtraOrderMoreContent">Even more <strong>exercises</strong>, <strong>games</strong> and <strong>videos</strong></span></li>
                <li><span i18n="@@discountExtraOrderExtraChapters">Learn how to type <strong>numbers</strong> and <strong>special characters</strong></span></li>
                <li><span i18n="@@discountExtraOrderTypingSpeed">Keep increasing your <strong>typing speed</strong></span></li>
                <li><span i18n="@@discountExtraOrderExtraYear">Get <strong>an extra year of access</strong></span></li>
            </ng-container>
            <ng-container *ngIf="!product.dlc">
                <li>
                    <span
                        i18n="@@website.shop.individual1"
                    ><strong>Valid for a whole year</strong></span>
                </li>
                <li>
                    <span
                        i18n="@@website.shop.individual2"
                    >Up to <strong>80% cheaper</strong> than alternatives</span>
                </li>
                <li>
                    <span
                        i18n="@@website.shop.individual3"
                    >Get <strong>instant access</strong></span>
                </li>
            </ng-container>
        </ul>

        <div class="purchaseInfo">
            <div *ngIf="product?.discountOnFullPrice > 0" class="discount">
                <p>
                    <span class="discountPercentage">-{{ product.discountOnFullPrice }}%</span>
                    <ng-container *ngIf="product.school" i18n="@@organization.studentDiscount">student discount</ng-container>
                    <ng-container *ngIf="product.business" i18n="@@organization.companyDiscount">company discount</ng-container>
                    <ng-container *ngIf="!product.school && !product.business">
                        {{ product.saleName ? product.saleName[languageService.getActiveLanguage().code] : '' }}
                    </ng-container>
                </p>
            </div>

            <div class="priceCta">
                <div class="pricing">
                    <span class="price">
                        <span *ngIf="product?.discountOnFullPrice > 0" class="originalPrice">
                            <span i18n="@@msrp" class="msrp">MSRP</span> <span class="priceNr">{{ product.fullPriceIncl | formatCurrency:currency }}</span>
                        </span>
                        <span class="priceNumber" [innerHTML]="product ? (product.calculatedIncl | formatCurrency:currency:true) : '&nbsp;'"></span>
                        <span class="exclIncl">
                            <ng-container i18n="@@inclTax">Incl. tax</ng-container>
                        </span>
                    </span>
                </div>

                <div class="orderBtn">
                    <button (click)="addToCart()" class="keyboardButton">
                        <span i18n="@@website.shop.order">Buy now</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
