import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../models/product';
import {Currency} from '../../../models/currency';
import {ModalService} from '../../services/modal.service';
import {LanguageService} from '../../services/language.service';
import {StateService} from '../../../services/state.service';
import {AppService} from '../../services/app.service';

@Component({
    selector: 'course-purchase-individual',
    templateUrl: './individual.component.html',
    styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent implements OnInit, AfterViewChecked {
    @Input() endOfTrial = false;
    @Input() trialCTA = false;
    @Input() product: Product;
    currency: Currency;
    languageCode: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public stateService: StateService,
        private modalService: ModalService,
        public languageService: LanguageService,
        private appService: AppService
    ) {
    }

    ngOnInit() {
        this.currency = this.stateService.getActiveCurrency();
        this.languageCode = this.languageService.getActiveLanguage().code;
    }

    ngAfterViewChecked() {
        this.initAnimations();
    }

    initAnimations() {
        if (this.endOfTrial || this.trialCTA) {
            // trialCTA & endOfTrial screens have their own animation, so we add a little delay
            setTimeout(
                () => {
                    this.appService.initAnimations('course-purchase-individual .slideLeft, course-purchase-individual .slideRight', 0.2);
                }, 1200
            );
        } else {
            this.appService.initAnimations('course-purchase-individual .slideLeft, course-purchase-individual .slideRight', 0.2);
        }
    }

    addToCart() {
        if (this.trialCTA) {
            this.modalService.closeModal();
        }

        this.stateService.setCartItems(1);

        let url = '/checkout';
        if (this.product.dlc) {
            url += '/upgrade';
        }

        this.router.navigate([url]);
    }
}

