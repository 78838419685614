import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {filter, first, map, mergeMap, skip, tap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Observable} from 'rxjs';
import {ModalService} from '../../shared/services/modal.service';
import {OrderService} from '../../shared/services/order.service';
import {StateService} from '../../services/state.service';
import {AppService} from '../../shared/services/app.service';
// import {UpdateVersionService} from '../../services/updateVersion.service';
import {AnalyticsService} from '../../services/analytics.service';
import {LanguageService} from '../../shared/services/language.service';

@Component({
    selector: 'course-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends OnDestroyMixin implements OnInit {
    activityLayout = false;
    checkoutLayout = false;
    panelLayout = false;
    overviewLayout = false;
    highContrast: Observable<boolean>;
    disableContentWrapper = false;

    constructor(
        public stateService: StateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private orderService: OrderService,
        public appService: AppService,
        // private updateVersionService: UpdateVersionService,
        private analyticsService: AnalyticsService,
        private modalService: ModalService,
        private languageService: LanguageService
    ) {
        super();
    }

    ngOnInit() {
        this.initRouteData();

        this.highContrast = this.stateService.personalSettings.getSetting('highContrast');
        this.orderService.restoreCartItems();

        this.initSkipFirstHeroAnimation();

        // this.updateVersionService.init();
        this.analyticsService.init();

        if (this.appService.isBrowser) {
            document.body.setAttribute(
                'lang-code',
                this.languageService.getActiveLanguage().code
            );
        }
    }

    getActiveUser() {
        return this.stateService.getActiveUser();
    }

    initSkipFirstHeroAnimation() {
        const router$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            skip(1),
            first()
        ).subscribe(
            () => {
                this.stateService.setFirstHeroAnimationSkipped(true);
            }
        );
    }

    initRouteData() {
        const router$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            mergeMap((route) => route.data),
            tap(
                (data) => {
                    // overview layout
                    this.overviewLayout = data.overviewLayout;

                    // activity layout
                    this.activityLayout = data.activityLayout;

                    // activity layout
                    this.checkoutLayout = data.checkoutLayout;

                    // panel layout
                    this.panelLayout = data.panelLayout;

                    // disable content wrapper
                    this.disableContentWrapper = data.disableContentWrapper;
                }
            )
        );

        router$.pipe(
            untilComponentDestroyed(this)
        ).subscribe();
    }

    openCookieSettings() {
        this.modalService.setModal('cookieConsent', {showSettings: true, canClose: true});
    }
}

