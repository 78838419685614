import {Inject, Injectable, LOCALE_ID, PLATFORM_ID} from '@angular/core';
import {StateService} from '../../services/state.service';
import {Language} from '../../models/language';
import {Currency} from '../../models/currency';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Injectable()
export class AppService {
    public isBrowser: boolean;

    // app settings
    public readonly maxLicensesOnlinePayment = {
        guest: 200,
        company: 500,
        school: 1000
    };
    public readonly activeCountries = {
        BE: {
            hasStates: false
        }
    };

    /*
    Languages: we could get this from server, but it makes the app startup slower,
    and it's really not necessary...
     */
    public readonly languages: Language[] = [
        new Language(1, 'nl', 'Nederlands'),
        new Language(2, 'en', 'English'),
        new Language(3, 'fr', 'Français')
    ];

    /*
    Currencies: we could get this from server, but it makes the app startup slower,
    and it's really not necessary...
     */
    public readonly currencies: Currency[] = [
        new Currency(1, 'EUR', 'Euro', '€'),
        new Currency(2, 'USD', 'United States Dollar', '$')
    ];

    /*
    Domain defaults: lists the language and default course for each domain.

    The i18nCode is used to list alternate language url for SEO purposes.
    We always want a default for each language (f.e. "nl"), multiple URL's with same language need to
    be more specific (f.e. "nl-BE").
     */
    public readonly domainDefaults: {
        [key: string]: {
            countryCode: string,
            language: string,
            i18nCode: string,
            defaultCourse: string,
            currencyId: number
        }
    } = {
        'tyyp.be': {
            countryCode: 'BE',
            language: 'nl',
            i18nCode: 'nl', // TODO when .nl becomes available, this becomes nl-BE
            defaultCourse: 'azerty_nl',
            currencyId: 1
        },
        'fr.tyyp.be': {
            countryCode: 'BE',
            language: 'fr',
            i18nCode: 'fr', // TODO when .fr becomes available, this becomes fr-BE
            defaultCourse: 'azerty_fr_be',
            currencyId: 1
        },
        // 'tyyp.nl': {
        //     countryCode: 'NL',
        //     language: 'nl',
        //     i18nCode: 'nl',
        //     defaultCourse: 'qwerty_nl',
        //     currencyId: 1
        // },
        // 'tyyp.fr': {
        //     countryCode: 'FR',
        //     language: 'fr',
        //     i18nCode: 'fr',
        //     defaultCourse: 'azerty_fr',
        //     currencyId: 1
        // },
        // 'tyyp.io': {
        //     countryCode: '',
        //     language: 'en',
        //     i18nCode: 'en',
        //     defaultCourse: 'qwerty_intl',
        //     currencyId: 2
        // }
    };

    constructor(
        private stateService: StateService,
        @Inject(PLATFORM_ID) platformId: object,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    startLoading(url: string, isBlocking: boolean, showLoader: boolean) {
        const requests = this.stateService.getLoadingRequests();
        requests.push({
            url,
            isBlocking,
            showLoader
        });

        this.stateService.setLoadingRequests(requests);
    }

    stopLoading(url: string) {
        let requests = this.stateService.getLoadingRequests();
        requests = requests.filter(
            (request) => request.url !== url
        );

        this.stateService.setLoadingRequests(requests);
    }

    setLoadingError() {
        this.stateService.setHasLoadingError(true);
    }

    clearLoadingError() {
        this.stateService.setHasLoadingError(false);
    }

    getDefaultDomainForLocale() {
        switch (this.locale) {
            case 'nl-BE':
                return 'tyyp.be';
        }

        return 'tyyp.be'; // TODO tyyp.io when it's ready
    }

    getHostname() {
        let hostname = this.document.location.hostname;
        if (!hostname) {
            hostname = 'www.' + this.getDefaultDomainForLocale();
        }

        return hostname;
    }

    getDomainDefaults() {
        const hostname = this.getHostname().replace('www.', '');

        let domainMatch;
        for (const domain of Object.keys(this.domainDefaults)) {
            if (
                hostname === domain
                || hostname === 'dev-' + domain
                || hostname === 'devprod-' + domain
            ) {
                domainMatch = domain;
                break;
            }
        }

        return this.domainDefaults[domainMatch];
    }

    clearActiveTabs() {
        this.stateService.activeTabs.viewOrganization.next(undefined);
        this.stateService.activeTabs.viewClass.next(undefined);
        this.stateService.activeTabs.viewStudent.next(undefined);
    }

    initAnimations(selector: string, threshold = 0.5) {
        if (!this.isBrowser) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(
                    (entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('inViewport');
                        }
                    }
                );
            }, {
                threshold
            });

        document.querySelectorAll(selector).forEach(
            (target) => {
                observer.observe(target);
            }
        );
    }
}
