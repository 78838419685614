<div class="features">
    <div class="websiteSectionTitle">
        <h2 i18n="@@website.features.title">The most fun way to learn to type</h2>
        <p
            i18n="@@website.features.sub"
        >In our proven learning method, exercises are alternated with fascinating how-to videos and fun typing games. Learning to type doesn't have to be boring!</p>
    </div>

    <div class="featureScroller">
        <button class="previousFeature linkBtn" (click)="previous()" i18n-aria-label="@@website.previous" aria-label="Previous"><mat-icon>arrow_back_ios_new</mat-icon></button>
        <div class="featureScrollerContainer">
            <div class="featureMenu">
                <button class="linkBtn" [class.active]="features[activeIndex] === 'fun'" (click)="goTo(0)" i18n="@@website.features.funShort">It's fun</button>
                <button class="linkBtn" [class.active]="features[activeIndex] === 'complete'" (click)="goTo(1)" i18n="@@website.features.completeShort">100% complete</button>
                <button class="linkBtn" [class.active]="features[activeIndex] === 'diploma'" (click)="goTo(2)" i18n="@@website.features.diplomaShort">Typing diploma</button>
                <button class="linkBtn" [class.active]="features[activeIndex] === 'online'" (click)="goTo(3)" i18n="@@website.features.onlineShort">Practice anywhere</button>
                <button class="linkBtn" [class.active]="features[activeIndex] === 'accessible'" (click)="goTo(4)" i18n="@@website.features.accessibleShort">Accessible</button>
            </div>

            <div class="featureContent" [class.isHiding]="isHiding">
                <div [class.visibleFeature]="features[contentActiveIndex] === 'fun'" id="fun">
                    <div class="featureDescription slideRight">
                        <h3 i18n="@@website.features.fun">Learning to type is fun</h3>
                        <p
                            class="description"
                            i18n="@@website.features.funInfo"
                        >Learning to type becomes a game with our interactive course.</p>
                        <ul class="checklist">
                            <li>
                                <span
                                    i18n="@@website.features.fun1"
                                >Many fun <strong>typing games</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.fun2"
                                >Earn <strong>stars</strong> and <strong>badges</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.fun3"
                                ><strong>Interactive exercises</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.fun4"
                                >Engaging <strong>how-to videos</strong></span>
                            </li>
                        </ul>
                    </div>
                    <div class="featureImg">
                        <img
                            ngSrc="assets/images/typingCourse/features/{{languageCode}}/learning_to_type_is_fun.webp"
                            i18n-alt="@@website.features.funAlt"
                            alt="Typing games"
                            class="slideLeft"
                            width="506"
                            height="379"
                        />
                    </div>
                </div>

                <div [class.visibleFeature]="features[contentActiveIndex] === 'complete'" id="complete">
                    <div class="featureDescription slideRight">
                        <h3 i18n="@@website.features.complete">100% complete</h3>
                        <p
                            class="description"
                            i18n="@@website.features.completeInfo"
                        >In a series of engaging how-to videos, we explain the best way to type in great detail.</p>
                        <ul class="checklist">
                            <li>
                                <span
                                    i18n="@@website.features.complete1"
                                >Engaging <strong>how-to videos</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.complete2"
                                >Focus on <strong>technique, posture</strong> and <strong>useful tips</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.complete3"
                                ><strong>Exercises</strong> to type faster and faster</span>
                            </li>
                        </ul>
                    </div>
                    <div class="featureImg">
                        <img
                            ngSrc="assets/images/typingCourse/features/complete_course.webp"
                            i18n-alt="@@website.features.completeAlt"
                            alt="Instruction videos"
                            class="slideLeft"
                            width="506"
                            height="379"
                        />
                    </div>
                </div>

                <div [class.visibleFeature]="features[contentActiveIndex] === 'diploma'" id="diploma">
                    <div class="featureDescription slideRight">
                        <h3 i18n="@@website.features.diploma">Get your typing diploma</h3>
                        <p
                            class="description"
                            i18n="@@website.features.diplomaInfo"
                        >After completing the course you can get your typing diploma.</p>
                        <ul class="checklist">
                            <li>
                                <span
                                    i18n="@@website.features.diploma1"
                                >Try <strong>as many times as you want</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.diploma2"
                                >Show off on your <strong>resume</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.diploma3"
                                ><strong>Guaranteed result!</strong></span>
                            </li>
                        </ul>
                    </div>
                    <div class="featureImg">
                        <img
                            ngSrc="assets/images/typingCourse/features/typing_diploma.webp"
                            i18n-alt="@@website.features.diplomaAlt"
                            alt="Typing diploma"
                            class="slideLeft"
                            width="506"
                            height="380"
                        />
                    </div>
                </div>

                <div [class.visibleFeature]="features[contentActiveIndex] === 'online'" id="online">
                    <div class="featureDescription slideRight">
                        <h3 i18n="@@website.features.online">Completely online</h3>
                        <p
                            class="description"
                            i18n="@@website.features.onlineInfo"
                        >All you need is a computer or tablet!</p>
                        <ul class="checklist">
                            <li>
                                <span
                                    i18n="@@website.features.online1"
                                ><strong>No installation</strong> needed</span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.online2"
                                >Works on <strong>any computer</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.online3"
                                >Practice on your <strong>tablet or iPad</strong></span>
                            </li>
                        </ul>
                    </div>
                    <div class="featureImg">
                        <img
                            ngSrc="assets/images/typingCourse/features/practice_everywhere.webp"
                            i18n-alt="@@website.features.onlineAlt"
                            alt="Typing on computer"
                            class="slideLeft"
                            width="506"
                            height="380"
                        />
                    </div>
                </div>

                <div [class.visibleFeature]="features[contentActiveIndex] === 'accessible'" id="accessible">
                    <div class="featureDescription slideRight">
                        <h3 i18n="@@website.features.accessible">For everyone</h3>
                        <p
                            class="description"
                            i18n="@@website.features.accessibleInfo"
                        >So that absolutely everyone can learn to type, we paid extra attention to:</p>
                        <ul class="checklist">
                            <li>
                                <span
                                    i18n="@@website.features.accessible1"
                                ><strong>Dyslexia</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.accessible2"
                                ><strong>Visually impaired</strong> or <strong>blind</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.accessible3"
                                ><strong>Colorblind</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.features.accessible4"
                                ><strong>Hard of hearing</strong> or <strong>deaf</strong></span>
                            </li>
                        </ul>
                    </div>
                    <div class="featureImg">
                        <img
                            ngSrc="assets/images/typingCourse/features/accessibility.webp"
                            i18n-alt="@@website.features.accessibleAlt"
                            alt="Accessible"
                            class="slideLeft"
                            width="506"
                            height="380"
                        />
                    </div>
                </div>
            </div>
        </div>
        <button class="nextFeature linkBtn" (click)="next()" i18n-aria-label="@@website.next" aria-label="Next"><mat-icon>arrow_forward_ios_new</mat-icon></button>
    </div>

    <p class="slideUp">
        <a *ngIf="parentPage === 'home'" [routerLink]="'/' + navigationService.getRouteTranslation('course')" mat-stroked-button color="primary" class="websiteButton">
            <span i18n="@@website.getStarted">Try it free</span>
        </a>

        <a
            *ngIf="parentPage === 'school' || parentPage === 'business'"
            routerLink="/{{ parentPage === 'school' ? 'school' : 'business' }}-registration"
            mat-stroked-button
            color="primary"
            class="websiteButton"
        >
            <span i18n="@@website.startFree">Start for free</span>
        </a>

        <a *ngIf="parentPage === 'purchase'" (click)="addToCart()" mat-stroked-button color="primary" class="websiteButton">
            <span i18n="@@website.shop.order">Buy now</span>
        </a>
    </p>
</div>
