import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Currency} from '../../../models/currency';
import {StateService} from '../../../services/state.service';
import {Product} from '../../../models/product';
import {Products} from '../../../models/abstract/products';
import {LanguageService} from '../../services/language.service';

@Component({
    selector: 'course-purchase-upgrade-to-full',
    templateUrl: './upgradeToFull.component.html',
    styleUrls: ['./upgradeToFull.component.scss'],
})
export class UpgradeToFullComponent implements OnInit {
    protected readonly Products = Products;
    @Input() basicProduct: Product;
    @Input() comboDealProduct: Product;
    @Input() upgradeProduct: Product; // This is not shown to the user, only used for the original DLC price
    @Input() quantity: number;
    @Input() showFeatures = true;
    @Output() toggleUpgrade: EventEmitter<boolean> = new EventEmitter<boolean>();
    currency: Currency;
    comboDealEnabled = false;
    languageCode: string;


    constructor(
        private stateService: StateService,
        private languageService: LanguageService
    ) {
    }

    ngOnInit() {
        this.currency = this.stateService.getActiveCurrency();
        this.languageCode = this.languageService.getActiveLanguage().code;
    }

    getComboDealListPrice() {
        return (Math.round(this.upgradeProduct.fullPriceIncl * this.quantity * 100) / 100);
    }

    getComboDealTotalPrice() {
        return (Math.round((this.comboDealProduct.calculatedIncl - this.basicProduct.calculatedIncl) * this.quantity * 100) / 100);
    }

    getUpgradeDiscount() {
        return Math.round(
            100 - ((this.comboDealProduct.calculatedIncl - this.basicProduct.calculatedIncl) / this.upgradeProduct.fullPriceIncl * 100)
        );
    }

    toggleFullVersion(evt) {
        this.comboDealEnabled = evt.checked;
        this.toggleUpgrade.emit(this.comboDealEnabled);
    }
}

