import {AfterViewChecked, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../models/product';
import {Currency} from '../../../models/currency';
import {NavigationService} from '../../../shared/services/navigation.service';
import {StateService} from '../../../services/state.service';
import {AppService} from '../../../shared/services/app.service';
import {first} from 'rxjs/operators';
import {ProductService} from '../../../shared/services/product.service';
import {RouteTranslations} from '../../../models/abstract/routeTranslations';
import {LanguageService} from '../../../shared/services/language.service';
import {DOCUMENT, formatDate} from '@angular/common';

@Component({
    selector: 'course-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit, AfterViewChecked {
    products: {
        individual: Product,
        school: Product,
        business: Product
    };
    mainProduct: Product;
    currency: Currency;
    languageCode: string;

    constructor(
        @Inject(DOCUMENT) private doc: Document,
        @Inject(LOCALE_ID) private locale: string,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public stateService: StateService,
        public navigationService: NavigationService,
        private appService: AppService,
        private productService: ProductService,
        private languageService: LanguageService
    ) {
    }

    ngOnInit() {
        this.currency = this.stateService.getActiveCurrency();
        this.languageCode = this.languageService.getActiveLanguage().code;

        this.productService.getBasicProducts().pipe(
            first()
        ).subscribe(
            (products: {individual: Product, school: Product, business: Product}) => {
                this.products = products;
                this.determineMainProduct();
            }
        );

        // test: scroll to an element
        // window.setTimeout(
        //     () => {
        //         // document.getElementsByClassName('organizations')[0].scrollIntoView(true);
        //         // document.getElementsByTagName('course-faq')[0].scrollIntoView(true);
        //     }, 100
        // );
        // end test
    }

    determineMainProduct() {
        const user = this.stateService.getActiveUser();

        if (!user || !user.organization) {
            this.mainProduct = this.products.individual;
        } else if (user.organization.isSchool) {
            this.mainProduct = this.products.school;
        } else {
            this.mainProduct = this.products.business;
        }

        this.addStructuredData();
    }

    addStructuredData() {
        const items = JSON.parse(this.doc.getElementById('structuredData').innerHTML);

        const offer: { [key: string]: any } = {
            '@type': 'Offer',
            url: 'https://'
                + this.appService.getHostname()
                + '/'
                + RouteTranslations.purchase[this.languageService.getActiveLanguageCode()],
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'https://schema.org/OnlineOnly',
            price: (Math.round(this.mainProduct.calculatedIncl * 100) / 100).toFixed(2),
            priceCurrency: this.currency.code,
            shippingRate: {
                '@type': 'MonetaryAmount',
                value: 0,
                currency: this.currency.code
            }
        };

        if (this.mainProduct.saleUntil) {
            offer.priceValidUntil = formatDate(this.mainProduct.saleUntil, 'yyyy-MM-dd', this.locale);
        }

        items.push({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: $localize`:@@website.shop.structuredData.productName:Tyyp - The most fun typing course!`,
            image: 'https://' + this.appService.getHostname() + '/assets/images/shop/' + this.languageCode + '/product_basic.webp',
            brand: {
                '@type': 'Brand',
                name: 'Tyyp'
            },
            description: $localize`:@@course.meta.description:Learn how to type with fun exercises, typing games and captivating instruction videos.`,
            offers: offer
        });

        const output = JSON.stringify(items);
        this.doc.getElementById('structuredData').innerHTML = output;
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('.individual .slideLeft, .individual .slideRight, .organizations .slideLeft, .organizations .slideRight', 0.2);
    }

    addToCart() {
        this.stateService.setCartItems(1);

        this.router.navigate(['/checkout']);
    }
}

